import React from 'react'
import { Navbar, Container } from 'react-bootstrap'

import logo from './images/logo-light.svg'

export const NavBar = () => {
  return (
    <Navbar
      bg="dark"
      variant="dark"
    >
      <Container>
        <Navbar.Brand href="https://interstone.su/">
          <img
            alt="Interstone"
            src={logo}
            className="d-inline-block align-top"
          />{' '}
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}
