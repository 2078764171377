import React, { useState, useEffect } from 'react'
import {
  Tabs,
  Tab,
  Container,
  Card,
  Table,
  Button,
  Spinner,
} from 'react-bootstrap'
import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { NavBar } from './navBar'

function App() {
  const params = new URLSearchParams(window.location.search)
  const encodedValue = params.get('zakaz')
  const implementationId = decodeURIComponent(encodedValue)
  let url = `https://sms.interstone.su/zakaz`

  const [loading, setLoading] = useState(true)
  const [deliveryData, setDeliveryData] = useState('')
  const [deliveryTime, setDeliveryTime] = useState('')
  const [driver, setDriver] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneURL, setPhoneURL] = useState('')
  const [car, setCar] = useState('')
  const [typeOfGoods, setTypeOfGoods] = useState('')
  const [weight, setWeight] = useState('')
  const [capacity, setCapacity] = useState('')
  const [address, setAddress] = useState('')
  const [deliveryUrl, setDeliveryUrl] = useState('')

  useEffect(() => {
    const currentDate = new Date()
    //currentDate.setDate(currentDate.getDate() + 1)
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`

    axios
      .post(url, { orderId: encodedValue })
      .then((data) => {
        const dateObj = new Date(data.data.deliveryData) || new Date()
        let timeOfDay = ''

        if (Number(data.data.sequence) === 0) {
          timeOfDay = 'Ожидайте в течении дня'
        } else {
          timeOfDay =
            Number(data.data.sequence) < 5
              ? 'Ожидайте на первую половину дня'
              : 'Ожидайте на вторую половину дня'
        }
        const newDateStr = formattedDate

        setDeliveryData(newDateStr)
        setDriver(data.data.driver)
        setPhone(data.data.phone)
        setPhoneURL(`tel:${phone}`)
        setDeliveryTime(timeOfDay)
        setCar(data.data.car)
        setTypeOfGoods(data.data.typeOfGoods)
        setWeight(data.data.weight)
        setCapacity(data.data.capacity)
        setAddress(data.data.address)
        setDeliveryUrl(data.data.deliveryUrl)
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }, [url, encodedValue, phone])

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: '15%',
          }}
        >
          <Spinner
            animation="border"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <NavBar key="0" />
          <div className="container">
            <Tabs
              key="1"
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                key="2"
                eventKey="home"
                title="Информация о доставке"
              >
                <Container key="3">
                  <Card key="4">
                    <Card.Body key="5">
                      <Card.Title>
                        <strong className="text-secondary">
                          Статус заказа № {implementationId}
                        </strong>
                      </Card.Title>
                      <Card.Body>
                        <Table
                          striped
                          bordered
                          hover
                        >
                          <strong>Состояние: </strong>{' '}
                          {'Товар готов к отгрузке'}
                        </Table>
                        <Table
                          striped
                          bordered
                          hover
                        >
                          <strong>Дата доставки: </strong>
                          <strong style={{ color: 'red' }}>
                            {deliveryData}
                          </strong>
                          {` | ${deliveryTime}`}
                        </Table>
                        <Table
                          key="6"
                          striped
                          bordered
                          hover
                        >
                          <strong>Водитель: </strong> {driver}
                        </Table>
                        <Table
                          key="7"
                          striped
                          bordered
                          hover
                        >
                          <strong>Телефон: </strong>{' '}
                          <a href={phoneURL}>{phone}</a>
                        </Table>
                        <Table
                          key="8"
                          striped
                          bordered
                          hover
                        >
                          <strong>Машина: </strong> {car} <br />
                        </Table>
                      </Card.Body>
                    </Card.Body>
                    <Card.Body key="13">
                      <Card.Title>
                        <strong className="text-secondary">
                          Информация о товаре
                        </strong>
                      </Card.Title>
                      <Card.Body>
                        <Table
                          key="9"
                          striped
                          bordered
                          hover
                        >
                          <strong>Вид товара: </strong> {typeOfGoods}
                        </Table>
                        <Table
                          key="10"
                          striped
                          bordered
                          hover
                        >
                          <strong>Вес (кг.): </strong> {weight}
                        </Table>
                        <Table
                          key="11"
                          striped
                          bordered
                          hover
                        >
                          <strong>Обьем: </strong> {capacity}
                        </Table>
                        <Table
                          key="12"
                          striped
                          bordered
                          hover
                        >
                          <strong>Адрес доставки: </strong> {address}
                        </Table>
                      </Card.Body>
                    </Card.Body>
                  </Card>
                </Container>
              </Tab>
            </Tabs>
          </div>
        </>
      )}
    </>
  )
}

export default App
